import $ from 'jquery';
import 'jquery-ui';
import { AjaxPromise } from "./shared/ajax_utilities";
import { BaseAutocomplete } from "./autocomplete/base_autocomplete";
import {generateGuid, getFilterKey, getFilterValue} from "./shared/common";
import { Validator } from '@cfworker/json-schema';
import {MultiAutocomplete} from "./autocomplete/multi_autocomplete";

class AutocompleteCore {
    AUTOCOMPLETES = {};
    AUTOCOMPLETE_CACHE = {};
    REFETCH_INTERVAL = 1000 * 60;
    AUTOCOMPLETE_CONTAINERS = {};
    LOADING = false;
    AUTOCOMPLETE_PROVIDERS = {};

    constructor() {
        app.AUTOCOMPLETE_CORE = this;

        // This is a helper hook event to allow on the fly rebuilds of auto completes
        window.addEventListener('RebuildAutocompletes', (_e) => {
            this.AUTOCOMPLETES = {};
            this.AUTOCOMPLETE_CACHE = {};
            this.REFETCH_INTERVAL = 1000 * 60;
            this.AUTOCOMPLETE_CONTAINERS = {};
            this.LOADING = false;
            this.AUTOCOMPLETE_PROVIDERS = {};
            this.#setupAutocompletes();
        });

        this.#setupAutocompletes();
        window.dispatchEvent(new Event('AutocompleteCoreSetup'));
    }

    #setupDOM($el, autocompleteData, aid, url, uid) {

        const placeholder = $el.attr('placeholder');
        const prefix = autocompleteData.tbl ?? aid;
        const containerClass = ($el.data('multi') === 'true') ? 'autocomplete-container autocomplete-container-multi' : 'autocomplete-container';
        let html = `
            <i class="btn-autocomplete-clear btn-autocomplete-clear-${prefix} icon fa fa-times f-14"></i>
            <i class="autocomplete-loading autocomplete-loading-${prefix} icon fad fa-spin fa-spinner-third"></i>
            <i class="autocomplete-search autocomplete-search-${prefix} icon fa fa-search"></i>`;

        if( $el.attr('disabled') && app.OPTIONS.list ) {
            html = '';
        }

        this.AUTOCOMPLETE_CONTAINERS[uid] = {
            container: autocompleteData.multi ? $el.parent().parent() : $el.parent(),
            input: $el,
        };

        $el.data('placeholder', placeholder && placeholder.length > 0 ? placeholder : '');
        this.AUTOCOMPLETE_CONTAINERS[uid].input_hidden = this.AUTOCOMPLETE_CONTAINERS[uid].container.find($el.attr('data-hidden') ? `#${$el.attr('data-hidden')}` : 'input[type="hidden"]')

        $el.after(html);
        this.AUTOCOMPLETE_CONTAINERS[uid].container.addClass(containerClass);
        this.AUTOCOMPLETE_CONTAINERS[uid].clear = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.btn-autocomplete-clear-${prefix}`);
        this.AUTOCOMPLETE_CONTAINERS[uid].search = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.autocomplete-search-${prefix}`);
        this.AUTOCOMPLETE_CONTAINERS[uid].loading = this.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.autocomplete-loading-${prefix}`);

        $el.unbind('focus').unbind('blur keyup').unbind('keydown');
        $el.on('focus', async (e) => {
            if(!this.AUTOCOMPLETES[uid] || !this.AUTOCOMPLETES[uid].onFocus) {
                return;
            }

            await this.AUTOCOMPLETES[uid].onFocus(e, uid);
        });

        $el.on('blur', async (e) => {
            if(!this.AUTOCOMPLETES[uid] || !this.AUTOCOMPLETES[uid].onBlur) {
                return;
            }

            await this.AUTOCOMPLETES[uid].onBlur(e, uid);
        });

        $el.on('keyup', async (e) => {
            if(!this.AUTOCOMPLETES[uid] || !this.AUTOCOMPLETES[uid].onKeyup) {
                return;
            }

            await this.AUTOCOMPLETES[uid].onKeyup(e, uid);
        });

        $el.on('keydown', async (e) => {
            if(!this.AUTOCOMPLETES[uid] || !this.AUTOCOMPLETES[uid].onKeyDown) {
                return;
            }

            this.AUTOCOMPLETES[uid].onKeyDown(e, uid);
        });

        $el.on('AutocompleteDependencyUpdated', async (e) => {
            this.AUTOCOMPLETE_CACHE[aid] = {};
            await this.setup(autocompleteData, $el, url, aid, uid)
        });

        this.AUTOCOMPLETE_CONTAINERS[uid].clear.unbind('click').on('click', (e) => {
            if(!this.AUTOCOMPLETES[uid] || !this.AUTOCOMPLETES[uid].clearSelection) {
                return;
            }

            this.AUTOCOMPLETES[uid].clearSelection(e, uid);
        });

        if(this.AUTOCOMPLETES[uid] && this.AUTOCOMPLETES[uid].setup) {
            this.AUTOCOMPLETES[uid].setup(uid);
        }

        return uid;
    }

    #setupAutocompletes() {
        $.each($('.generic-autocomplete[data-depends-on]'), async (k, el) => {
            await this.#autocompleteBuilder(k, el)
        });
        $.each($('.generic-autocomplete[data-provides]'), async (k, el) => {
            await this.#autocompleteBuilder(k, el)
        });
        $.each($('.generic-autocomplete:not([data-provides]):not([data-depends-on])'), async (k, el) => {
            await this.#autocompleteBuilder(k, el)
        });
    }

    async #autocompleteBuilder(K, el) {
        el = $(el);
        const autocompleteData = el.data();
        if(!autocompleteData || !autocompleteData.aid) {
            console.warn(`Unable to setup auto complete for input missing aid.`, el);
            return;
        }

        const aid = autocompleteData.aid;
        let url;
        if(autocompleteData.url) {
            url = autocompleteData.url;
        } else if(autocompleteData.tbl) {
            url = `${app.CACHE.URL_AJAX}${autocompleteData.tbl}/get`;
        } else {
            console.warn(`Unable to get data for autocomplete ${aid}. No data-url or data-tbl attribute provided.`);
            return;
        }

        let uid;
        if(!el.parent().data('uid') || el.parent().data('uid').length === 0) {
            uid = generateGuid(12);
        } else {
            uid = el.parent().data('uid');
        }

        if(!this.AUTOCOMPLETES[uid]) {
            this.AUTOCOMPLETES[uid] = autocompleteData.multi ? new MultiAutocomplete(aid, autocompleteData.tbl ?? aid, uid) : new BaseAutocomplete(aid, autocompleteData.tbl ?? aid, uid);
        }

        const dependsOn = el.data('depends-on');
        if(dependsOn && dependsOn.length > 0) {
            window.addEventListener('AutocompleteProviderRegistered', async (e) => {
                const provider = this.AUTOCOMPLETE_PROVIDERS[dependsOn];
                if(!provider) {
                    return;
                }
                await this.#startSetup(el, autocompleteData, aid, url, uid);
            });
            return;
        }

        await this.#startSetup(el, autocompleteData, aid, url, uid);
    }

    async #startSetup(el, autocompleteData, aid, url, uid) {
        this.#setupDOM(el, autocompleteData, aid, url, uid);
        if(app.DASHBOARD_CORE || app.OPTIONS.list) {
            el.on('focus', async (e) => {
                await this.setup(autocompleteData, el, url, aid, uid);
            });

            if(this.AUTOCOMPLETE_CONTAINERS[uid] && this.AUTOCOMPLETE_CONTAINERS[uid].input &&
                this.AUTOCOMPLETE_CONTAINERS[uid].input.val().trim().length > 0
            ) {
                this.showClear(uid);
            } else {
                this.showSearch(uid);
            }
        } else {
            await this.setup(autocompleteData, el, url, aid, uid);
        }
    }

    async setup(autocompleteData, el, url, ref, uid) {

        if(!this.AUTOCOMPLETE_CACHE[ref] || !this.AUTOCOMPLETE_CACHE[ref].data) {
            await this.#getAutocompleteData(url, ref, autocompleteData, uid);
        }

        if(!this.AUTOCOMPLETE_CACHE[ref]) {
            console.warn('Unable to fetch autocomplete data.');
            this.AUTOCOMPLETE_CONTAINERS[uid].input.prop('disabled', true).addClass('disabled');
            this.hideAll(uid);
            return;
        }

        this.showSearch(uid);
        if(this.AUTOCOMPLETE_CONTAINERS[uid] && this.AUTOCOMPLETE_CONTAINERS[uid].input && this.AUTOCOMPLETE_CONTAINERS[uid].input.val() && this.AUTOCOMPLETE_CONTAINERS[uid].input.val().length > 0) {
            this.showClear(uid);
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].input.prop('disabled', false);
        const nextFetchTime = (this.AUTOCOMPLETE_CACHE[ref].lastFetched + this.REFETCH_INTERVAL);
        if(nextFetchTime <= Date.now() || this.AUTOCOMPLETE_CACHE[ref].errored) {
            await this.#getAutocompleteData(url, ref, autocompleteData, uid);
        }

        if(this.AUTOCOMPLETE_CACHE[ref].errored) {
            el.attr('placeholder', this.AUTOCOMPLETE_CACHE[ref].data).prop('disabled', true).addClass('disabled');
            this.hideAll(uid);
            return;
        }

        el.attr('placeholder', this.AUTOCOMPLETE_CONTAINERS[uid].input.data('placeholder')).prop('disabled', false).removeClass('disabled');

        if(typeof el.autocomplete('instance') !== "undefined") {
            el.autocomplete('destroy'); // Destroy the old instance if one exists
        }

        const inputData = this.AUTOCOMPLETE_CONTAINERS[uid].input[0].dataset;
        let minLength = 1;

        // setup minimum length
        if( this.AUTOCOMPLETE_CACHE[ref].data && this.AUTOCOMPLETE_CACHE[ref].data.length <= 10 ){
            minLength = 0;
        } else if( inputData.hasOwnProperty('minlength') ) {
            minLength = parseInt(inputData.minlength);
        }

        let opts = {};

        if(this.AUTOCOMPLETES[uid] && this.AUTOCOMPLETES[uid].autocompleteOptions) {
            opts = await this.AUTOCOMPLETES[uid].autocompleteOptions(autocompleteData, uid);
        }

        if(!this.AUTOCOMPLETE_CACHE[ref].data || this.AUTOCOMPLETE_CACHE[ref].data.length === 0) {
            const msg = `No ${app.TBL[autocompleteData.tbl ?? ref].ii} Found.`
            this.AUTOCOMPLETE_CONTAINERS[uid].input.val(msg).attr('placeholder', msg).attr('disabled', 'true');
            this.AUTOCOMPLETE_CONTAINERS[uid].input_hidden.val('');
            this.hideAll(uid);
            return;
        }

        this.#checkDataFromUri(ref, uid, this.AUTOCOMPLETE_CACHE[ref].data ?? []);

        el.autocomplete({
            ...opts,
            minLength: minLength,
            source: this.AUTOCOMPLETE_CACHE[ref].data,
        });

        if(minLength === 0) {
            el.on('focus', (e) => {
                if(!e.currentTarget.value) {
                    $(e.currentTarget).autocomplete('search', '');
                }
            });
        }

        const provides = el.data('provides');
        if(provides && provides.length > 0 && !this.AUTOCOMPLETE_PROVIDERS[provides]) {
            this.AUTOCOMPLETE_PROVIDERS[provides] = this.AUTOCOMPLETES[uid];
            window.dispatchEvent(new CustomEvent('AutocompleteProviderRegistered', {
                detail: {
                    ref, uid
                }
            }));
        } else if(provides && provides.length > 0 && this.AUTOCOMPLETE_PROVIDERS[provides]) {
            console.warn('duplicate autocomplete providers detected please check what provides:', provides);
        }
    }

    #checkDataFromUri(ref, uid, json)
    {
        const uri = [3,4,5,6];

        uri.forEach(index => {
            if( getFilterKey(index) === ref){

                const row = json.filter( r => {
                    if( r.value == getFilterValue(index) ) {
                        return r;
                    }
                });

                if( row[0] ) {
                    this.AUTOCOMPLETES[uid].CONTAINER.input.val(row[0].label);
                    this.AUTOCOMPLETES[uid].CONTAINER.input_hidden.val(row[0].value);
                    this.showClear(uid);
                }
            }
        });
    }

    async #getAutocompleteData(url, ref, autocompleteData, uid) {
        try {
            this.LOADING = true;
            this.showLoading(uid);
            console.time(`Autocomplete Load for UID: ${uid} under TBL ${ref}`);
            let opts = {
                url: url,
                method: autocompleteData.ajaxMethod ?? 'POST',
                data: autocompleteData ?? {},
            };

            if(this.AUTOCOMPLETES[uid] && this.AUTOCOMPLETES[uid].getAjaxData) {
                opts.data = await this.AUTOCOMPLETES[uid].getAjaxData(autocompleteData);

                if(!opts.data) {
                    this.AUTOCOMPLETE_CACHE[ref] = {
                        lastFetched: Date.now(),
                        errored: true,
                        data: '',
                    };
                    console.warn('Request halted by options request.');
                    return;
                }
            }

            const res = await AjaxPromise(opts);

            this.LOADING = false;
            if(this.AUTOCOMPLETE_CONTAINERS[uid] && this.AUTOCOMPLETE_CONTAINERS[uid].input) {
                if(this.AUTOCOMPLETE_CONTAINERS[uid].input.val()) {
                    this.showClear(uid);
                } else {
                    this.showSearch(uid);
                }
            } else {
                this.showSearch(uid);
            }

            if(res.status !== 'success') {
                this.AUTOCOMPLETE_CACHE[ref] = {
                    lastFetched: Date.now(),
                    errored: true,
                    data: 'Unable to fetch data.',
                };
                console.warn(`Failed to fetch data for autocomplete ${ref}.\n${url}:`, res);
                return;
            }

            const schema = {
                type: 'array',
                items: {
                    type: 'object',
                    required: ['label', 'value'],
                    properties: {
                        label: 'string',
                        value: 'string',
                    }
                }
            };
            const validation = new Validator(schema);
            const { valid, errors } = validation.validate(res.data ?? []);
            if(!valid) {
                this.AUTOCOMPLETE_CACHE[ref] = {
                    lastFetched: Date.now(),
                    errored: true,
                    data: 'Unable to fetch data.',
                };
                console.warn(`Invalid JSON response ignoring response.`, ...(errors.map((error) => `\n${error.error}`)));
                return;
            }

            if(!res.data) {
                this.AUTOCOMPLETE_CACHE[ref] = {
                    lastFetched: Date.now(),
                    errored: true,
                    data: `No ${app.TBL[ref].ii} Found.`,
                };
                return;
            }

            this.AUTOCOMPLETE_CACHE[ref] = {
                lastFetched: Date.now(),
                errored: false,
                data: res.data,
            };

        } catch(err) {
            this.AUTOCOMPLETE_CACHE[ref] = {
                lastFetched: Date.now(),
                errored: true,
                data: 'Unable to fetch data.',
            };
            console.warn(`Failed to fetch data for autocomplete ${ref}.\n${url}:`, err);
        } finally {
            this.showSearch(uid);
            console.timeEnd(`Autocomplete Load for UID: ${uid} under TBL ${ref}`);
        }
    }

    showClear(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.show();
    }
    showLoading(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.show();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.hide();
    }

    showSearch(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.show();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.hide();
    }

    hideAll(uid) {
        if(!this.AUTOCOMPLETE_CONTAINERS[uid]) {
            return;
        }

        this.AUTOCOMPLETE_CONTAINERS[uid].search.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].loading.hide();
        this.AUTOCOMPLETE_CONTAINERS[uid].clear.hide();
    }
}

$(() => {
    new AutocompleteCore();
});